import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from 'components/Layout';
import { IntroContainer, Intro1, Intro2 } from 'components';
import Logo from '../images/logo.svg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isDesktop, isMobile } from 'react-device-detect';
import { AnimatePresence, motion } from 'framer-motion';

const IndexPage = () => {
  const introContainerRef = React.useRef();
  const cloudBgRef = React.useRef();
  const cloudTopRef = React.useRef();

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const introContainer = introContainerRef.current;

    gsap.fromTo(
      cloudBgRef.current,
      {
        y: isMobile ? '-10%' : '-30%',
      },
      {
        y: '0',
        ease: 'linear',
        scrollTrigger: {
          trigger: undefined,
          scrub: 1,
        },
      }
    );

    gsap.fromTo(
      cloudTopRef.current,
      {
        y: isMobile ? '-30%' : '-10%',
      },
      {
        y: '-50%',
        ease: 'linear',
        scrollTrigger: {
          trigger: introContainer.querySelector('#intro-1'),
          scrub: 1,
        },
      }
    );

    const blocks = introContainer.querySelectorAll('.benefit');

    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: introContainer.querySelector('#intro-2'),
          start: 'top 50%',
          markers: false,
          // entering, leaving, entering backwards, back past the beginning
          toggleActions: 'play reverse play reverse',
        },
      })
      .fromTo(blocks, { scale: 0 }, { scale: 1, stagger: 0.2 });
  }, []);

  return (
    <Layout fixed={true} pageTitle="Digital printed coffee packaging">
      <IntroContainer ref={introContainerRef}>
        <div
          id="bg-image"
          className="fixed -z-3 top-0 transform-gpu"
          ref={cloudBgRef}
        >
          <StaticImage
            src="../images/flatbackground.jpg"
            alt="SB5D"
            placeholder="blurred"
            layout="fixed"
            formats={['AUTO', 'WEBP', 'AVIF']}
          />
        </div>
        <motion.div
          id="mask-1"
          className="fixed -z-1 top-0 transform-gpu"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.25 }}
        >
          <StaticImage
            src="../images/skymask1.png"
            alt="SB5D"
            placeholder="blurred"
            layout="fixed"
            formats={['AUTO', 'WEBP', 'AVIF']}
          />
        </motion.div>
        <div
          ref={cloudTopRef}
          id="mask-2"
          className="fixed -z-2 top-0 transform-gpu"
        >
          <StaticImage
            src="../images/skymask2.png"
            alt="SB5D"
            placeholder="blurred"
            layout="fixed"
            formats={['AUTO', 'WEBP', 'AVIF']}
          />
        </div>
        <section className="container lg:max-w-4xl relative z-0">
          <Intro1 />
          <Intro2 />
        </section>
      </IntroContainer>
    </Layout>
  );
};

export default IndexPage;
